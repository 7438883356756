













































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import moment from 'moment';
import { AdvertService } from '@/services/advert-service';
//引入组件
import VueUeditorWrap from "vue-ueditor-wrap"; //es6
import chooseGroup from '@/views/advertPage/chooseGroup.vue';
import { UploadStatus } from '@/views/wecom/video/index.vue';
import { WecomVideoServices } from '@/services/wecom-video-service';
import { ElForm } from 'element-ui/types/form';
import { cloneDeep } from 'lodash';

let localForm: any = {};
@Component({
  components: {
    VueUeditorWrap,
    chooseGroup
  }
})
export default class advertApplication extends Vue {
  public labelPosition: String = 'top';
  public title: String = '可见范围';
  public dialogFormVisible: Boolean = false;
  public channelName: String = '';
  public uploadStatus: UploadStatus = 'unupload';
  public progress: number = 0;
  public fileList: any[] = [];
  public myConfig = {
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 500,
    // 初始容器宽度
    initialFrameWidth: "100%",
    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    serverUrl: '/api/application/ueditor/config',
    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
    UEDITOR_HOME_URL: "/ueditor/",
    toolbars: [[
      'fullscreen', 'source', '|', 'undo', 'redo', 'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript',
      'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', 'forecolor',
      'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc',
      'fontfamily', 'fontsize', 'simpleupload', 'insertimage', 'emotion', /*'scrawl',*/
            /*'music',*/ 'insertvideo', 'insertaudio', 'attachment', 'map',
      'rowspacingtop', 'rowspacingbottom', 'lineheight', 'link', 'unlink'
    ]],
    'fontfamily': [
      { label: '', name: 'songti', val: '宋体,SimSun' },
      { label: '仿宋', name: 'fangsong', val: '仿宋,FangSong' },
      { label: '仿宋_GB2312', name: 'fangsong', val: '仿宋_GB2312,FangSong' },
      { label: '', name: 'kaiti', val: '楷体,楷体_GB2312, SimKai' },
      { label: '', name: 'yahei', val: '微软雅黑,Microsoft YaHei' },
      { label: '', name: 'heiti', val: '黑体, SimHei' },
      { label: '', name: 'lishu', val: '隶书, SimLi' },
      { label: '', name: 'andaleMono', val: 'andale mono' },
      { label: '', name: 'arial', val: 'arial, helvetica,sans-serif' },
      { label: '', name: 'arialBlack', val: 'arial black,avant garde' },
      { label: '', name: 'comicSansMs', val: 'comic sans ms' },
      { label: '', name: 'impact', val: 'impact,chicago' },
      { label: '', name: 'timesNewRoman', val: 'times new roman' }
    ]
  };
  public form: any = {
    id: '',
    channelName: '',
    groupList: [],
    groupListShow: '',
    linkData: '',
    agentId: '',
    openTime: '3',
    forwardType: 'appad',//跳转类型
    startTime: '',//开始时间
    endTime: '',
    htmlContent: '',
    content: '',//公共内容
    times: [],
    submitTime: '',//提交时间
    lookTime: '',//预览时间
    lookContent: '',//预览内容
    deleted: 0,//是否：0否，1是
    backgroud: 0, // 0否，1是
    isVideoAdvert: 0,
    videoContentModel: 0,
    videoContentType: 0,
    videoUrl: '',
    videoId: '',
    videoFileName: ''
  };
  public backgroud1: number = 0; // 0否，1是 
  public query: any = {
    agentId: '',
    forwardType: 'appad'
  }
  public rules: any = {
    channelName: [
      { required: true, message: '请输入广告名称', trigger: ['blur', 'change'] }],
    externalUrl: [
      { validator: this.checkUrl, trigger: ['blur', 'change'] }
    ],
    groupListShow: [
      { required: true, message: '请选择可见范围', trigger: ['blur', 'change'] }],
    openTime: [
      { required: true, message: '请输入开屏时长', trigger: ['blur', 'change'] }],
    times: [
      { required: true, message: '请选择有效期', trigger: ['blur', 'change'] }],
    content: [
      {
        validator: (rule: any, value: any, cb: any) => {
          if (localForm.isVideoAdvert === 0 && localForm.content === '') {
            return cb(new Error('请输入正文'));
          }
          cb()
        },
        trigger: ['blur', 'change']
      }
    ],
    videoId: [
      { validator: this.checkVideoId, trigger: ['blur'] }
    ],
    videoFileName: [
      { validator: this.checkFileName }
    ],
  }

  @Inject(AdvertService) private advertService!: AdvertService;
  @Inject(WecomVideoServices) private wecomVideoServices !: WecomVideoServices
  @Watch('form', { deep: true })
  watchForm(value: any) {
    console.log(value.videoContentType !== localForm.videoContentType);
    if (value.videoContentType !== localForm.videoContentType) {
      (this.$refs.form as ElForm).clearValidate('videoFileName');
      (this.$refs.form as ElForm).clearValidate('videoId');
    }
    if (value.content) {
      (this.$refs.form as ElForm).clearValidate('content');
    }
    localForm = cloneDeep(value);
  }
  public checkVideoId(rule: any, value: any, cb: any) {
    console.log('localForm', localForm)
    if (localForm.isVideoAdvert === 1 && localForm.videoContentType === 0 &&  !localForm.videoId) {
      return cb(new Error('请输视频ID'));
    }
    if (localForm.videoId  && localForm.videoContentType === 0 && localForm.isVideoAdvert === 1) {
      return this.getVideoById(this.form.videoId);
    }
    cb()
  }
  public checkFileName(rule: any, value: any, cb: any) {
    if (localForm.isVideoAdvert === 1 && localForm.videoContentType === 1 && !localForm.videoFileName) {
      return cb(new Error('请上传视频'));
    }
   
    cb()
  }
  public getVideoById(id: number | string) {
    return new Promise((resolve, reject) => {
      this.wecomVideoServices.videoDetail(id).then((res) => {
        if (res) {
          resolve('');
          const { urlPath, height, width } = res;
          this.form.videoUrl = urlPath;
          this.form.videoContentModel = height > width ? 1 : 0;
          return;
        }
        reject('请输入正确的视频ID');
      }).catch(() => {
        reject('请输入正确的视频ID');
      })
    })
  }
  public videoIdBlur(e: any) {
    (this.$refs.form as ElForm).clearValidate('videoId');
    (this.$refs.form as ElForm).validateField('videoId');
  }
  public videoTypeChange() {

  }
  public async created(): Promise<void> {
    if (this.$route.query.id) {
      this.channelName = localStorage.getItem('channelName') || '';
      this.query.agentId = this.form.agentId = this.$route.query.id
      if (this.$route.query.detailId) {
        this.form.id = this.$route.query.detailId;
        this.selectChannelInfoDetail()
      }
    }
  }
  //验证地址以https开头
  public async checkUrl(rule: any, value: any, cb: any) {
    const regUrl = 'https'
    if (value == undefined || value == null || value == '') {
      return cb()
    }
    let strValue = value.slice(0, 5);
    if (regUrl == strValue) {
      // 合法的输入
      return cb()
    }
    cb(new Error('请以https开头!'))
  }
  //预览
  public preview() {
    let link = `${location.origin}/wecom-mfa-front/#/index?id=` + this.form.id + "&PC=1&backgroud=" + this.backgroud1;
    window.open(link);
  }
  //获取广告信息
  public async selectChannelInfoDetail(): Promise<void> {
    const res = await this.advertService.selectChannelInfoDetail(this.form.id);
    if (res != null) {
      //应用分组数据回显处理
      this.formatData(res.groupList)
      const { isVideoAdvert, videoContentModel, videoContentType, videoUrl, videoFileName } = res;
      this.form = {
        ...res,
        isVideoAdvert: isVideoAdvert || 0,
        videoContentModel: videoContentModel || 0,
        videoContentType: videoContentType || 0
      }
      if (videoFileName) {
        this.fileList = [{
          urlPath: videoUrl,
          name: videoFileName,
        }]
      }

      this.backgroud1 = this.form.backgroud
      if (this.form.lookContent && this.form.lookContent.length > 0) {
        this.form.content = this.form.lookContent;
      } else {
        this.form.content = this.form.htmlContent;
      }
      this.$set(this.form, 'times', [this.form.startTime, this.form.endTime])
    }
  }
  //应用分组数据回显处理
  public async formatData(data: any) {
    //应用分组数组回显处理
    let arr: any = []
    for (var item = 0; item < data.length; item++) {
      arr.push(data[item].groupName)
    }
    this.$nextTick(function () {
      let list = arr.join(',');
      this.$set(this.form, 'groupListShow', list)
    });
  }
  //选择可见范围
  public choose() {
    this.dialogFormVisible = true;
  }

  public submitForm(formName: any): void {
    const currentTime = new Date().getTime();
    if (formName == '1') { //提交         
      this.form.htmlContent = this.form.content;
      this.form.lookContent = '';
      this.form.backgroud = this.backgroud1;
    } else {
      this.form.lookTime = moment(currentTime).format('YYYY-MM-DD HH:mm:ss')
      this.form.lookContent = this.form.content;
    }

    this.form.startTime = this.form.times[0]
    this.form.endTime = this.form.times[1]


    const addRef = (this.$refs.form as ElForm);
    addRef.validate(async (valid: any) => {
      if (valid) {
        const res = await this.advertService.saveChannelInfo(this.form);
        this.$message({
          message: '保存应用开屏成功',
          type: 'success'
        });
        if (formName == '0') {
          this.form.id = res.id
          this.preview()
        } else {
          this.$router.go(-1);
        }
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }
  public handleRemove(file: any) {
    this.form.urlPath = '';
  }
  public async beforeRemove(file: any, fileList: any) {
    try {
      const res = await this.$confirm('确定要移除当前视频文件吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      this.$message({
        message: '已移除',
        type: 'success'
      });
      return res;
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消操作'
      });
      return Promise.reject(e)
    }
  }
  public handleChange() {
    this.fileList = [];
  }
  public handlePreview(file: any) {
    console.log(file);
    const { urlPath, name } = file;
    const loading = this.$loading({
      text: '下载中...'
    })
    const link = document.createElement('a');
    link.target = '_blank';
    link.style.display = 'none';
    link.href = urlPath;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    loading.close();
  }
  public beforeUpload(files: any) {
    // 文件类型进行判断
    const isVideo = files.type === "video/mp4";
    const isLt500M = files.size / 1024 / 1024 < 500;
    if (!isVideo) {
      this.$message.error('上传文件格式错误!');
      this.fileList = [];
      return false;
    } else {
      if (!isLt500M) {
        this.$message.error('上传文件大小不能超过 500MB!');
        this.fileList = [];
        return false;
      } else {
      }
    }

    return isVideo && isLt500M;
  }
  public customUpload(file: any) {
    const formData = new FormData();
    formData.append('file', file.file);
    const loading = this.$loading({
      text: `上传中...`,
    });
    this.uploadStatus = 'uploading';
    this.wecomVideoServices.videoUpload(formData, {
      onUploadProgress: (event: any) => {
        if (event.lengthComputable) {
          var progress = Math.round((event.loaded * 100) / event.total) > 99 ? 99 : Math.round((event.loaded * 100) / event.total);
          this.progress = progress;
        }
      }
    }).then((res) => {
      this.progress = 100;
      const { code, video } = res;
      if (code === 100200) {
        const { urlPath, fileName, height, width } = video;
        const postVideo = {
          videoFileName: fileName,
          videoUrl: urlPath
        };
        this.form = {
          ...this.form,
          ...postVideo,
          videoContentModel: height > width ? 1 : 0
        }
        this.uploadStatus = 'upload-success';
        this.fileList = [{ ...postVideo, name: fileName }];
        (this.$refs.form as ElForm).clearValidate('videoUrl');
        return
      }
      this.$message({
        type: 'error',
        message: '上传视频失败'
      })
      this.uploadStatus = 'upload-fail'
    }).catch((err) => {
      console.log('error --> ', err);
      this.uploadStatus = 'upload-fail'
      this.$message({
        type: 'error',
        message: '上传视频失败'
      })
    }).finally(() => {
      loading.close();
      this.progress = 0;
    });
  }
  //得到子组件的值
  public selectChange(e: any): void {
    if (e == false) {
      this.dialogFormVisible = false;
      return;
    }
    this.form.groupList = [];
    e.forEach((item: Dict<any>) => {
      let json: any = {}
      json.groupName = item.groupName
      json.id = item.id
      this.form.groupList.push(json)
    });
    this.formatData(this.form.groupList)
    this.dialogFormVisible = false;
  }
  private mounted() {

  }
}
