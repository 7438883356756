







































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { AdvertService } from '@/services/advert-service';

@Component({
  components: {
   
  }
})
export default class addClassify extends Vue {
  @Prop({ default: true }) private title!: String; 
  @Prop({ default: true }) private agentId!: String; 
  @Prop({ default: true }) private dialogFormVisible!: Boolean; 
  @Prop({ default: true }) private groupList!: any; 
  
  public labelPosition: String = 'top';
  public multipleSelection:any = [];
  public queryParams: any = { //查询参数  
    agentId:this.agentId,
    groupName:'',
    pages: 1, 
    size: 5,
    current: 1, 
    total: 0
  };
  private tableData: any[] = [];
  private applications: any[] = [];
  
  @Inject(AdvertService) private advertService!: AdvertService;
  public async created(): Promise<void> { 
     await this.getList();     
  }
  public getRowKeys(row:any) {
    return row.id;
  }
  //默认选中
  public checkData(){
    this.$nextTick(()=>{
        this.tableData.forEach(row => {
            let indexs = JSON.stringify(this.groupList).indexOf(row.id)
            if( indexs != -1){ //不等于-1 表示存在
              const checkRef = (this.$refs.multipleTable as any)
              checkRef.toggleRowSelection(row,true);
            }
        })
    })  
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.queryParams.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.queryParams.current = current;
    this.getList();
  }
  public search() {
    this.queryParams.current = 1;
    this.getList()
  }
  public handleSelectionChange(val:any) {
    this.multipleSelection = val;
  }
  //获取数据源
  public async getList() : Promise<void>{
    const res = await this.advertService.selectGroupList(this.queryParams);
    this.tableData = res.records; 
    this.checkData()   
    this.queryParams.total = res.total;
    this.queryParams.pages = res.pages;
    this.queryParams.size = res.size;
    this.queryParams.current = res.current; 
  }
  //保存
  @Emit('selectChange')
  public submitForm() : void{
    return this.multipleSelection;
  }
  //返回
  @Emit('selectChange')
  public cancel(): Boolean {
    return false
  }
  public checkimage() {
   this.dialogFormVisible = true;
  }  
}
